<template>
  <v-container class="container--fluid fill-height accent lighten-1">
    <v-row
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        sm="8"
        md="4"
        lg="4"
      >
        <v-card elevation="0" class="pa-3 rounded-3 card-box">
          <v-card-title class="d-flex align-center justify-center">
            <div class="layout column align-center">
              <v-img
                src="@/assets/logo.png"
                alt="Bina logo"
                width="120"
              />
            </div>
          </v-card-title>
          <v-card-text>
            <h1 class="mt-4 primary--text text--darken-1">
              {{ $t('login.welcomeTo') }}
            </h1>
            <p class="mt-2 caption">
              {{ $t('login.pleaseSignIn') }}
            </p>
          </v-card-text>
          <v-card-text>
            <v-form ref="form" v-model="valid" @submit="login" onSubmit="return false;">
              <v-text-field
                v-model="model.email"
                name="email"
                append-icon="mdi-account"
                :label="$t('login.email')"
                type="email"
                :rules="emailValid"
                required
                autocomplete="username"
              />
              <v-text-field
                v-model="model.password"
                name="password"
                :rules="passwordValid"
                :label="$t('login.password')"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                @click:append="showPassword = !showPassword"
                required
                autocomplete="current-password"
              />
              <v-row align="baseline" no-gutters>
                <v-col cols="12">
                  <v-checkbox
                    dense
                    hide-details="auto"
                    v-model="is_remember"
                    :label="$t('login.rememberMe')"
                  />
                </v-col>
              </v-row>
              <!-- <div class="text-right">
                <a
                  class="accent--text text--lighten-2"
                >
                  {{ $t('login.changePassword') }}
                </a>
              </div> -->
              <div class="mt-8">
                <localization />
                <v-btn
                  class="text-capitalize"
                  color="primary"
                  :disabled="!valid"
                  :loading="loading"
                  type="submit"
                  width="80%"
                >
                  {{ $t('login.singIn') }}
                  <template v-slot:loader>
                    <span>{{ $t('common.loading') }}</span>
                  </template>
                </v-btn>
                <p class="ma-0 mt-6 caption text-center" v-if="rememberUser">
                  <span class="font-weight-bold">{{$t('login.lastLoggedIn')}}:</span> {{new Date(rememberUser.last_logged_in).toLocaleString('sv-SE')}}
                </p>
              </div>
              <!-- <div class="text-center mt-4">
                <span>
                  {{ $t('login.newPlatform') }} <router-link class="accent--text text--lighten-2" class-active="active" to="/singup" >{{ $t('login.createAccount') }}</router-link>
                </span>
              </div> -->
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
      <img
        class="auth-mask-bg"
        height="173"
        :src="require(`@/assets/images/mask-light.png`)"
      >
      <v-img
        class="auth-tree"
        width="247"
        height="185"
        src="@/assets/images/tree.png"
      ></v-img>
      <v-img
        class="auth-tree-3"
        width="377"
        height="289"
        src="@/assets/images/tree-3.png"
      ></v-img>
    </v-row>
  </v-container>
</template>

<script>
import Localization from '@/views/widget/AppLocalization.vue';
import { mapActions, mapGetters } from "vuex";

export default {
  name: 'SingIn',
  components: { Localization },
  data: () => ({
    loading: false,
    valid: false,
    is_remember: false,
    model: {},
    showPassword: false
  }),
  computed: {
    emailValid() {
      return [
        v => !!v || this.$t('formRules.emailRequired') ,
        v => /.+@.+\..+/.test(v) || this.$t('formRules.emailValidMessage')
      ];
    },
    passwordValid() {
      return [
        v => !!v || this.$t('formRules.passwordRequired'),
      ];
    },
    ...mapGetters ({
      rememberUser: "rememberUser"
    })
  },
  mounted() {
    if (this.rememberUser) {
      this.is_remember = true;
      this.model.email = this.rememberUser.email;
    }
  },
  methods: {
    ...mapActions({
      loginByEmail: "LoginByEmail",
    }),
    async login() {
      this.loading = true;
      const params = {
        email: this.model.email,
        password: this.model.password,
      };
      this.loginByEmail(params)
      .then((data) => {
        if (this.is_remember) {
          this.$store.commit('SET_REMEMBER_USER', {last_logged_in: data.user.last_logged_in, email: data.user.email}); 
        } else {
          this.$store.commit('SET_REMEMBER_USER', null);
        }
        this.$router.push({ path: '/' });
        this.loading = false;
      })
      .catch((e) => {
        this.loading = false;
        console.log(e);
      });
    },
  },
};
</script>
<style scoped>
.card-box {
  box-shadow: 0 2px 10px 0 rgb(94 86 105 / 10%) !important;
  z-index: 1;
}
.auth-mask-bg {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.auth-tree,
.auth-tree-3 {
  position: absolute;
}
.auth-tree {
  bottom: 0;
  left: 0;
}
.auth-tree-3 {
  bottom: 0;
  right: 0;
}
</style>
